// PrivacyAndPolicy

import React from 'react';
import Pricing from 'views/PrivacyAndPolicy';

const PricingPage = () => {
  return <Pricing />;
};

export default PricingPage;
