import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        // display={'flex'}
        // justifyContent={'space-between'}
        // alignItems={{ xs: 'flex-start', sm: 'center' }}
        // flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
          Privacy & Policy
          </Typography>
          <Typography variant={'h6'}></Typography>
        </Box>
      </Box>
      <Divider sx={{ marginY: 6 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={18} md={12}>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              
            </Typography>
            <Typography component={'p'} align='justify'>
            Thank you for visiting NPS Yeshwanthpur website.<br /><br />
            National Public School is committed to maintaining your trust by protecting personal information we collect about you, and this statement outlines the school’s policy on how the school uses and manages personal data provided to or collected by the school.
            </Typography>
          </Box>
          <br/>
          <Box marginBottom={3}>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            INFORMATION WE COLLECT

            </Typography>
            <Typography component={'p'} align='justify'>
             National Public School may collect information about you from a variety of sources, including information we collect from you directly during pre-admissions, when you intend to admit your child in NPS or otherwise provided directly to us. Generally we collect information about an individual by way of application form filled out by you, discussion, meetings, interviews and telephone calls during and after the course of a student’s admission at the school.
            </Typography>
            <br/>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            PERSONAL INFORMATION

            </Typography>
            <Typography component={'p'} align='justify'>
            NPS may collect the information mentioned below directly during the admission process which includes personal and sensitive information.
            </Typography>
            

            
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'Student’s name, Parents or Guardians name.',
                'Email address, telephone or mobile number, postal address, etc',
                'Student’s age, date of birth, details of family members, siblings, etc',
                'Contact information of relatives, friends, etc, who come into contact with the school',
                'Payment information, if payment information is made through cheque, net banking, credit or debit card, etc',
                'Medical ailment.',
                'Future communication preference.',
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            HOW WE USE THIS INFORMATION
            </Typography>
            <Typography component={'p'} align = 'justify'>
            We provide information to students and parents by way of digital or electronic communication such as email, mobile, SMS, phone call and postal mail. We collect the information to provide the progress, improvement and other information about the student’s matters related to their schooling. We also provide information on day to day administrative activities, events, celebrations, competitions, sports and others activities organised by the school.
            </Typography>
            <br />
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            INFORMATION SHARING AND SECURITY
            </Typography>
            <Typography component={'p'} align = 'justify'>
            The success of every business relationship depends on trust and we do not reveal any personal information outside National Public School which we collect from you. We are committed to keep personal information secure and have appropriate technical, physical and administrative procedures in place to protect personal information from loss, misuse or alteration.
            </Typography>
            <br />
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            INFORMATION TRANSFER
            </Typography>
            <Typography component={'p'} align = 'justify'>
            We limit access to personal information to those who have a business need or third parties provided we protect the information not to use the information for any other purpose. We may disclose personal information to government departments, medical practitioners, any one authorized by an individual to receive their information, people providing service to the school, including visiting teachers, sport coaches, etc.
            <br />
            Policy changes: The National Public School Committee will post substantive changes to this policy at least thirty (30) days before such changes take effect. Any information the National Public School, Yeshwanthpur collects under the current website privacy policy will remain subject to the terms of this website privacy policy. After any changes take effect, all new information the National Public School, Yeshwanthpur collects, if any, will be subject to the new policy.
            </Typography>
            <br />
            <Typography variant={'h6'} fontWeight={700} gutterBottom>
            COOKIES
            </Typography>
            <Typography component={'p'} align = 'justify'>
            The cookies are unique bits of computer data that many major website will transfer to your computer the first time that you visit. Cookies are stored on your hard drive and may be later accessed by the website to track prior usage. Cookies may also be used to deliver customized and personalized services and information by tracking which pages a user has already visited. We do not store any of your personal information in cookies, web and beacons or other technologies on our computer. Hence, you are not required to provide any personal information through this website unless you choose to do so. We cannot guarantee confidentiality of e-mails and we therefore recommend that you do not send confidential information via e-mail. If for any reason you are concerned that the personal information available at NPS is not correct or needs to be changed, you may please contact us at www.npsypr.com for the same.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
